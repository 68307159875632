import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Autocomplete
} from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch } from 'react-redux';
import { Student } from 'src/models/student';
import {
  PaymentForCredits,
  PaymentSubmitTypes
} from 'src/actions/PaymentActions';
import { GetLocalUser } from 'src/actions/AuthActions';
import { DatePicker, DesktopDatePicker } from '@mui/lab';
import {
  GetAllStudents,
  StudentSubmitTypes,
  StudentsGetTypes
} from 'src/actions/StudentActions';
import { roundToNearestQuarter } from 'src/tools/validation';
import { getStudentIDAndFullName } from 'src/tools/stringTools';

const color = '#ffffff';

interface studentInterface {
  label: string;
  value: string;
}

type ButtonProps = {
  student?: Student;
  label?: string;
};

const PurchaseCreditsButton = ({
  student: student,
  label: label
}: ButtonProps) => {
  const [paymentSubmitType, setPaymentSubmitType] =
    useState<PaymentSubmitTypes>();
  const [allStudentsData, setAllStudentsData] = useState<StudentsGetTypes>();
  const [allStudents, setAllStudents] = useState<studentInterface[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [open, setOpen] = useState(false);
  const [isHourCredit, setIsHourCredit] = useState(true);

  const dispatch = useDispatch();

  //Form Data
  const [formData, setFormData] = useState({
    note: '',
    receipt: '',
    amountPaid: 0,
    student: { label: '', value: '' },
    credits: 1
  });

  const [errors, setErrors] = useState<{
    date: string;
    note: string;
    receiptNumber: string;
    amountPaid: string;
    credits: string;
    student: string;
  }>({
    date: '',
    note: '',
    receiptNumber: '',
    amountPaid: '',
    credits: '',
    student: ''
  });

  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const onChangedDate = (event) => {
    setDate(event.value);
  };

  const onCreditChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = roundToNearestQuarter(Number(event.target.value));
    if (value <= 0) {
      value = 0.25;
    }

    setFormData((prevState) => ({
      ...prevState,
      credits: value
    }));

    //Set Payment
    setFormData((prevState) => ({
      ...prevState,
      amountPaid: student
        ? student.hourly_rate * value
        : getUpdatedPaymentAmount(formData.student.value, value)
    }));
  };

  const onStudentChanged = (event, newValue) => {
    //Set Student
    setFormData((prevState) => ({
      ...prevState,
      student: newValue
    }));

    //Set Payment
    setFormData((prevState) => ({
      ...prevState,
      amountPaid: getUpdatedPaymentAmount(newValue.value, formData.credits)
    }));
  };

  const getUpdatedPaymentAmount = (student_id: string, credits: number) => {
    if (allStudentsData && allStudentsData.type == 'Success') {
      const selectedStudent: Student = allStudentsData.payload.find(
        (item) => item._id == student_id
      );

      if (!selectedStudent) {
        return;
      }
      return selectedStudent.hourly_rate * credits;
    }
  };

  //Initial Load
  useEffect(() => {
    if (!student) {
      if (!allStudents || allStudents.length == 0) {
        dispatch<any>(GetAllStudents(setAllStudentsData));
      }
    }
  }, []);

  //Initial Load
  useEffect(() => {
    if (allStudentsData && allStudentsData.type == 'Success') {
      let studentList: studentInterface[] = allStudentsData.payload
        .filter((a) => a.status == 'active')
        .sort((a, b) => (a.user_id < b.user_id ? -1 : 1))
        .map((student) => ({
          label: getStudentIDAndFullName(student),
          value: student._id
        }));
      setAllStudents(studentList);
    }
  }, [allStudentsData]);

  //On Submit of the form
  const onSubmit = () => {
    if (!handleValidation()) {
      return;
    }

    console.log('here');

    dispatch<any>(
      PaymentForCredits(
        student ? student._id : formData.student.value,
        GetLocalUser()._id,
        date,
        formData.credits,
        formData.amountPaid,
        formData.receipt,
        formData.note,
        setPaymentSubmitType
      )
    );
  };

  //Validate form
  const handleValidation = () => {
    let formIsValid = true;

    return formIsValid;
  };

  useEffect(() => {
    if (paymentSubmitType && paymentSubmitType.type == 'Success') {
      window.location.reload();
    }
  }, [paymentSubmitType]);

  const handleClickOpen = () => {
    setOpen(true);

    const defaultValues = {
      date: new Date(),
      note: '',
      receipt: '',
      amountPaid: student ? student.hourly_rate : 0,
      student: { label: '', value: '' },
      credits: 1
    };

    setFormData(defaultValues);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Dialog fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>
          {isHourCredit ? (
            <>
              {student
                ? 'Purchase Hours - ' + getStudentIDAndFullName(student)
                : 'Purchase Hours'}
            </>
          ) : (
            <>
           {student
                ? 'Add Payment - ' + getStudentIDAndFullName(student)
                : 'Add Payment'}
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {student ? (
              <></>
            ) : (
              <>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="student"
                    options={allStudents}
                    value={formData.student}
                    onChange={onStudentChanged}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        label="Student"
                        margin="dense"
                        error={errors.student.length > 0}
                        helperText={
                          errors.student.length > 0 ? errors.student : ''
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {formData.student.value || student ? (
              <>
                <Grid item xs={4}>
                  <DatePicker
                    label="Payment Date"
                    onChange={setDate}
                    value={date}
                    PaperProps={{
                      sx: {
                        svg: { color: 'white' },
                        span: { color: 'white' }
                      }
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          style={{ marginRight: '10px' }}
                          sx={{
                            svg: { color }
                          }}
                          margin="dense"
                          id="date"
                        />
                      );
                    }}
                  />
                </Grid>
                {isHourCredit && (
                  <Grid item xs={4}>
                    <TextField
                      required
                      fullWidth={true}
                      margin="dense"
                      name="credits"
                      id="credits"
                      label="Credits"
                      value={formData.credits}
                      onChange={onCreditChanged}
                      type="number"
                    />
                  </Grid>
                )}

                <Grid item xs={4} paddingRight={0.5}>
                  <TextField
                    margin="dense"
                    id="amountPaid"
                    name="amountPaid"
                    value={formData.amountPaid}
                    error={errors.amountPaid.length > 0}
                    helperText={
                      errors.amountPaid.length > 0 ? errors.amountPaid : ''
                    }
                    onChange={onChanged}
                    label="Payment"
                    type="number"
                    fullWidth={true}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="receipt"
                    required={true}
                    name="receipt"
                    label="Receipt Number"
                    type="text"
                    fullWidth={true}
                    onChange={onChanged}
                    value={formData.receipt}
                    error={errors.receiptNumber.length > 0}
                    helperText={
                      errors.receiptNumber.length > 0
                        ? errors.receiptNumber
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="note"
                    label="Notes"
                    type="text"
                    name="note"
                    multiline={true}
                    onChange={onChanged}
                    value={formData.note}
                    error={errors.note.length > 0}
                    helperText={errors.note.length > 0 ? errors.note : ''}
                    fullWidth={true}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onSubmit}>Add</Button>
        </DialogActions>
      </Dialog>

      <Button
        sx={{ mt: { xs: 2, md: 0 } }}
        variant="contained"
        size="small"
        startIcon={<PaidIcon fontSize="small" />}
        onClick={handleClickOpen}
      >
        {label ? label : 'Purchase'}
      </Button>
    </>
  );
};

export default PurchaseCreditsButton;
