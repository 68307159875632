import {
  Box,
  Container,
  LinearProgress,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { GetLocalUser } from 'src/actions/AuthActions';
import {
  LessonsGetTypes,
  GetTutorLessons
} from 'src/actions/LessonActions';
import Footer from 'src/components/Footer';
import UnpaidLessons from './UnPaidLessonsTable';

const DashboardReceivables = () => {
  const [lessonData, setLessonData] = useState<LessonsGetTypes>();

  const dispatch = useDispatch();
  let user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    //If there is a user id in the url params
    if (user != null) {
      dispatch<any>(GetTutorLessons(setLessonData, null, 'unpaid'));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Payroll</title>
      </Helmet>
      <Box pt={2} />

      <Container maxWidth="lg">
        <h1>Receivables</h1>
        {lessonData && lessonData.type == 'Success' ? (
          <UnpaidLessons allLessons={lessonData.payload} />
        ) : (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default DashboardReceivables;
